import { MethodCode, ModelCode } from '../../@types/Permission';
import { findPermission } from '../../sections/@dashboard/Permissions/utils';
var useUserPermissions = function (user) {
    var hasUserPermissionList = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.USER, MethodCode.LIST);
    var hasUserPermissionCreate = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.USER, MethodCode.CREATE);
    var hasUserPermissionView = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.USER, MethodCode.VIEW);
    var hasUserPermissionEdit = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.USER, MethodCode.EDIT);
    var hasUserPermissionDelete = findPermission(user === null || user === void 0 ? void 0 : user.permissionGroup, user === null || user === void 0 ? void 0 : user.extraPermissions, ModelCode.USER, MethodCode.DELETE);
    return {
        hasUserPermissionList: hasUserPermissionList,
        hasUserPermissionCreate: hasUserPermissionCreate,
        hasUserPermissionView: hasUserPermissionView,
        hasUserPermissionEdit: hasUserPermissionEdit,
        hasUserPermissionDelete: hasUserPermissionDelete,
    };
};
export default useUserPermissions;
